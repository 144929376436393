<template>
  <div>
    <v-row>
      <v-col cols="3">
        <credentials-page-filter-status />
      </v-col>
      <v-col cols="3">
        <credentials-page-filter-query />
      </v-col>
      <v-col cols="2">
        <v-btn
          @click="setActiveProxy"
        >
          Set proxy for active creds
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          class="primary"
          @click="getChallenged"
        >
          Get challenged creds
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div>
          <credential-item
            v-for="cred of credentialsList"
            :key="cred._id"
            :credential="cred"
          />
          <v-progress-linear
            v-if="loading"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CredentialsPageFilterStatus from '@/components/credentialsPage/credentialsPageFilters/CredentialsPageFilterStatus';
  import CredentialsPageFilterQuery from '@/components/credentialsPage/credentialsPageFilters/CredentialsPageFilterQuery';

  import CredentialItem from '@/components/credentialsPage/CredentialItem';
  import { mapGetters } from 'vuex';
  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';
  import { queryFiltersMixin } from '@/mixins/QueryFiltersMixin';
  import { setProxyForActiveCreds } from '@/api/proxiesMethods';
  import { getChallenged } from '@/api/credentialsMethods';

  export default {
    name: 'CredentialsPage',
    components: {
      CredentialsPageFilterStatus,
      CredentialsPageFilterQuery,
      CredentialItem,
    },
    mixins: [lazyLoadMixin, queryFiltersMixin],
    computed: {
      ...mapGetters({
        credentialsList: 'credentials/credentialsList',
        credentialsFilters: 'credentials/filters',
      }),
    },
    async created () {
      if (Object.keys(this.$route.query).length) {
        this.loading = true;
        this.setFiltersByQuery('credentials/SET_FILTERS');
        await this.$store.dispatch('credentials/LOAD_CREDENTIALS_LIST');
        this.loading = false;
      } else {
        this.setQueryByFilters(this.credentialsFilters);
      }
    },
    methods: {
      handleScroll: async function () {
        const bottomOfWindow =
          document.documentElement.scrollTop.toFixed() +
          window.innerHeight.toFixed() >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow && this.canLoadMore && !this.loading) {
          this.loading = true;
          const count = this.credentialsList.length;
          await this.$store.dispatch('credentials/LOAD_CREDENTIALS_LIST', count);
          this.canLoadMore = this.credentialsList.length > count;
          this.loading = false;
        }
      },
      setActiveProxy () {
        setProxyForActiveCreds();
      },
      getChallenged () {
        getChallenged();
      },
    },
  };
</script>

<style scoped>
</style>
