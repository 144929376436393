<template>
  <v-text-field
    v-model="query"
    v-debounce:1000="setQuery"
    placeholder="Username or id or space"
  />
</template>

<script>
  import { queryFiltersMixin } from '@/mixins/QueryFiltersMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CredentialsPageFilterQuery',
    mixins: [queryFiltersMixin],
    data () {
      return {
        query: '',
        loaded: true,
        searchQueue: false,
      };
    },
    computed: {
      ...mapGetters({
        filters: 'credentials/filters',
      }),
    },
    mounted () {
      this.query = this.filters.q.value;
    },
    methods: {
      async setQuery () {
        if (this.loaded) {
          this.searchQueue = false;
          await this.$store.dispatch('credentials/SET_FILTERS', { q: { value: this.query } });
          await this.setQueryByFilters(this.filters);

          await this.$store.dispatch('credentials/LOAD_CREDENTIALS_LIST');
          if (this.searchQueue) {
            await this.setQuery();
          }
        } else {
          this.searchQueue = true;
        }
      },
    },
  };
</script>

<style scoped>

</style>
